import { NgModule                          } from '@angular/core';
import { CommonModule                      } from '@angular/common';
import { CreateComponent                   } from './create/create.component';
import { EditComponent                     } from './edit/edit.component';
import { SharedFormsModule                       } from 'app/shared/forms/forms.module';
import { TranslationModule                 } from 'app/core/translate/translate.module';
import { AppCommonModule                   } from 'app/common/common.module';
import { SharedComponentsModule                  } from 'app/shared//components/components.module';
import { SubmitPaneComponent } from '@app/shared/components/submit-pane/submit-pane.component';

@NgModule({
  declarations: [
    CreateComponent,
    EditComponent
  ],
  imports: [
    CommonModule,
    SharedFormsModule,
    TranslationModule,
    SharedComponentsModule,
    AppCommonModule,
    SubmitPaneComponent,
  ]
})
export class DivisionsModule { }
