import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { ReactiveFormsModule,
         FormsModule                     } from '@angular/forms';

import { TranslationModule               } from '@core/translate/translate.module';
import { AppCommonModule                 } from '@common/common.module';
import { SharedPipesModule                     } from '@app/shared/pipes/pipes.module'
import { SharedFormsModule as AppForms         } from 'app/shared/forms/forms.module';

import { SharedComponentsModule                } from '@app/shared/components/components.module';

import { ValidatorComponent              } from './components/validator/validator.component';

import { SyncComponent                   } from './sync.component';
import { FilterCollectionsPipe,
         SyncErrorPipe                   } from './sync.pipe';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { TooltipModule } from '@app/shared/directives/tooltip/tooltip.module';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@NgModule({
  declarations: [
    SyncComponent,
    SyncErrorPipe,
  ],
  imports: [
    CommonModule,
    AppForms,
    AppCommonModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    ValidatorComponent,
    FilterCollectionsPipe,
    FormsModule,
    SharedPipesModule,
    TranslationModule,
    LoadingComponent,
    TooltipModule
  ],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true }
    },
    // date picker display options
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ]
})
export class SyncModule { }
