import { Component,
         Output,
         EventEmitter,
         Input                         } from '@angular/core';
import { FormControl                   } from '@angular/forms';
import { takeUntilDestroyed            } from '@angular/core/rxjs-interop';
import { BehaviorSubject,
         filter,
         map,                          } from 'rxjs';
import { identify,
         MapInstance                   } from '@royalschedule/maps';

import { LoggerService                 } from 'app/core';


export type SelectedMap = MapInstance;
export type Event = SelectedMap;

type MapAlternatives = {
  determined?: MapInstance;
  strong:      MapInstance[];
  weak:        MapInstance[];
};

@Component({
    selector: 'app-select-map',
    templateUrl: './select-map.component.html',
    styleUrls: ['./select-map.component.scss'],
    standalone: false
})
export class SelectMapComponent {
  @Output() onSelect = new EventEmitter<Event>();
  public selected = new BehaviorSubject<Event | null>(null);

  protected readonly loading = new BehaviorSubject(true);
  protected readonly error   = new BehaviorSubject(false);

  protected maps = new BehaviorSubject<MapAlternatives | null>(null);
  protected ctrl = new FormControl<MapInstance[]>([]);

  constructor (
    private _logger: LoggerService
  ) {

    this.ctrl.valueChanges
    .pipe(
      takeUntilDestroyed(),
      map(x => x?.at(0)),
      filter(Boolean)
    )
    .subscribe(x => {
      this.onSelect.emit(x);
      this.selected.next(x);
    });
  }

  @Input()
  set input (value: unknown) {
    this.loading.next(true);
    try {
      const { determined, strong, weak } = identify(value);

      if (determined || strong.length || weak.length) this.maps.next({ determined, strong, weak });
      else                                            this.maps.next(null);

      if (determined) this.ctrl.setValue([determined]);

      this.loading.next(false);
      this.error.next(false);
    } catch (err) {
      this._logger.error(err);

      this.loading.next(false);
      this.error.next(true);
    }
  }

}
