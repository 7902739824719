import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { DragDropModule                  } from '@angular/cdk/drag-drop';
import { ScrollingModule                 } from '@angular/cdk/scrolling';
import { NgPipesModule                   } from 'ngx-pipes';

import { SharedDirectivesModule                } from '@app/shared/directives/directives.module';
import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { SharedComponentsModule as AppShared   } from 'app/shared/components/components.module';
import { SharedPipesModule                     } from 'app/shared/pipes/pipes.module';
import { CoursesComponent                } from './courses.component';
import { PipesModule as _PipesModule     } from '../../pipes/pipes.module';
import { FilterModule                    } from '../filter/filter.module';
import { SearchComponent } from '@app/shared/components/search/search.component';



@NgModule({
  declarations: [
    CoursesComponent,
  ],
  exports: [
    CoursesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppShared,
    SharedPipesModule,
    DragDropModule,
    ScrollingModule,
    AppCommonModule,
    TranslationModule,
    NgPipesModule,
    _PipesModule,
    FilterModule,
    SharedDirectivesModule,
    SearchComponent
  ]
})
export class CoursesModule { }
