import { Injectable                      } from '@angular/core';
import { utils as XLSXUtils,
         read  as XLSXRead               } from 'xlsx';
import { Observable,
         Subject,
         firstValueFrom,
         from,
         map,
         of,
         switchMap                       } from 'rxjs';
import Papa                                from 'papaparse';
import Excel                               from 'exceljs';

import { HttpService,
         LoggerService                   } from 'app/core';
import { apiConstants                    } from 'app/constants';
import { parseWorkbook                   } from 'app/shared/services/excel-template/functions';


type FileType = 'csv' | 'txt' | 'xlsx' | 'json' | 'mdb' | string;

export type ParsedFile = {
  type:     FileType;
  raw:      File;
  content?: any;
}

function toObject (headers: unknown, rows: unknown[]): Record<string, any>[] {
  if ( ! Array.isArray(headers)) return [];
  return rows.map((row: any) => Object.fromEntries(headers.map((header: string, i: number) => [header, row[i]])))
}

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor (
    private _logger: LoggerService,
    private _http:   HttpService
  ) { }

  public parse (raw: File): Promise<ParsedFile> {
    const subj = new Subject<ParsedFile>();

    // fetch extension
    const extension: FileType | undefined = raw.name.split('.').pop()?.toLowerCase();

    // must have an extension
    if ( ! extension) return Promise.reject(new Error('No file extension'));

    // create a file reader
    const fileReader = new FileReader();
    fileReader.onload = e => {
      try {
        this._parse(raw, fileReader.result, extension)
        .pipe(map(content => ({ type: extension, raw, content })))
        .subscribe(subj);
      } catch(err) {
        this._logger.error(err);
        subj.error(err);
      }
    }

    // parse the file
    switch (extension) {
      case 'csv':
      case 'txt':
      case 'json':
        fileReader.readAsText(raw, 'UTF-8');
        break;
      case 'xlsx':
      case 'xlsm':
        fileReader.readAsBinaryString(raw);
        break;
      case 'mdb':
        fileReader.readAsBinaryString(raw);
        break;
      default:
        Promise.resolve({ raw, type: extension });
        break;
    }

    return firstValueFrom(subj);
  }

  private _parse (
    file:        File,
    fileContent: FileReader['result'],
    extension:   FileType
  ): Observable<any> | never {
    switch (extension) {
      case 'txt':
        return of(fileContent as string);
      case 'csv':
        try         { return of(Papa.parse(fileContent as string, { header: true }).data); }
        catch (err) { break;                                                               }
      case 'json':
        try         { return of(JSON.parse(fileContent as string)); }
        catch (err) { throw new Error('JSON not valid');            }
      case 'xls':
      case 'xlsx':
      case 'xlsm':
        const workbook = XLSXRead(fileContent, { type: 'binary' });
        const sheetNames: string[] = workbook.SheetNames;

        // attempt to parse using the excel template service
        const metaSheet = workbook.Sheets['meta'];
        if (metaSheet) {
          const [headers, ...rows] = XLSXUtils.sheet_to_json(metaSheet, { header: 1, blankrows: false });
          const structure = toObject(headers, rows)[1]?.structure;

          if (structure && typeof structure == 'string' && structure.includes('RS/Excel-')) {
            // find the major version of RS/Excel-X.Y.Z
            const version = structure.split('-')[1].split('.').map(Number);
            if (version[0] >= 2) {
              return from(file.arrayBuffer())
              .pipe(
                switchMap(x => {
                  const workbook = new Excel.Workbook();
                  return from(workbook.xlsx.load(x))
                }),
                map(x => parseWorkbook(x))
              );
            }
          }
        }


        if (sheetNames.length == 1) {
          const [headers, ...rows] = XLSXUtils.sheet_to_json(workbook.Sheets[sheetNames[0]], { header: 1, blankrows: false });
          if (! Array.isArray(headers))
            throw new Error('(Shared::Services::File) Header for xlsx type was not of array type');
          return of(toObject(headers, rows));
        }

        const sheets = sheetNames.reduce((acc, sheet: string) => {
          // parse sheet
          const [_headers, ...rows] = XLSXUtils.sheet_to_json(workbook.Sheets[sheet], { header: 1, blankrows: false });

          // skip sheets without headers
          if ( ! Array.isArray(_headers)) return acc;

          // remove all column names that are not strings or numbers
          const headers = _headers.filter((x): x is string | number => typeof x === 'string' || typeof x === 'number');

          // convert row from array to object
          try {
            const data = toObject(headers, rows);
            return Object.assign(acc, { [sheet]: data });
          } catch (error) {
            console.warn(error);
            return acc;
          }
        }, { } as Record<string, any>);
        return of(sheets);
      case 'mdb':
        return this._mdb2json(file);
    }

    throw new Error('No valid file extension found');
  }

  private _mdb2json (file: File): Observable<any> {
    // uploads and converts the input to json while also throwing away unnecessary data
    return this._http
    .fileAndData(`${ apiConstants.FILE_CONVERSION }/mdb/json`, file, { })
  }



}
