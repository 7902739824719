import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { DragDropModule                  } from '@angular/cdk/drag-drop';

import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';

import { SharedComponentsModule as AppShared   } from 'app/shared/components/components.module';
import { SharedPipesModule                     } from 'app/shared/pipes/pipes.module';

import { ComponentsModule                } from './components/components.module';
import { ParallelCoursesComponent  } from './parallel-courses.component';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';

@NgModule({
  declarations: [
    ParallelCoursesComponent
  ],
  exports: [
    ParallelCoursesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    AppShared,
    SharedPipesModule,
    DragDropModule,
    AppCommonModule,
    TranslationModule,
    LoadingComponent
  ]
})
export class ParallelCoursesModule { }
