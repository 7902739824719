import { NgModule                             } from '@angular/core';
import { CommonModule                         } from '@angular/common';
import { FormsModule, ReactiveFormsModule     } from '@angular/forms';
import { RouterModule                         } from '@angular/router';
import { NgxCleaveDirectiveModule             } from 'ngx-cleave-directive';

import { AppCommonModule                      } from 'app/common/common.module';
import { TranslationModule                    } from 'app/core/translate/translate.module';
import { SharedFormsModule as MyFormsModule         } from 'app/shared/forms/forms.module';
import { SharedComponentsModule                     } from 'app/shared/components/components.module';
import { SharedPipesModule                          } from 'app/shared/pipes/pipes.module';
import { SharedTablesModule                         } from 'app/shared/tables/tables.module';

import { DivisionsModule                      } from './divisions/divisions.module';
import { UploadFileModule                     } from './upload-file/upload-file.module';
import { ParallelCoursesModule                } from './parallel-courses/parallel-courses.module';
import { SyncModule                           } from './sync/sync.module';
import { CompanyComponent                     } from './company/company.component';
import { TermsDialogComponent                 } from './terms-dialog/terms-dialog.component';
import { ChangePasswordComponent              } from './change-password/change-password.component';
import { LoginComponent                       } from './login/login.component';
import { UnsavedChangesComponent              } from './unsaved-changes/unsaved-changes.component';
import { VersionModule                        } from './version/version.module';
import { UnrecoverableAppStateModule          } from './unrecoverable-app-state/unrecoverable-app-state.module';
import { FormFieldErrorComponent              } from '../components/form-field-error/form-field-error.component';
import { SubmitPaneComponent } from '../components/submit-pane/submit-pane.component';

@NgModule({
  declarations: [
    CompanyComponent,
    TermsDialogComponent,
    ChangePasswordComponent,
    LoginComponent,
    UnsavedChangesComponent,
  ],
  exports: [
    CompanyComponent,
    TermsDialogComponent,
    ChangePasswordComponent,
    DivisionsModule,
    ParallelCoursesModule,
    UploadFileModule,
    VersionModule,
    UnrecoverableAppStateModule,
  ],
  imports: [
    CommonModule,
    MyFormsModule,
    FormsModule,
    ReactiveFormsModule,
    SyncModule,
    AppCommonModule,
    TranslationModule,
    SharedTablesModule,
    SharedComponentsModule,
    SharedPipesModule,
    RouterModule,
    NgxCleaveDirectiveModule,
    FormFieldErrorComponent,
    SubmitPaneComponent
  ]
})
export class SharedDialogsModule { }
