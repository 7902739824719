import { AfterViewInit,
         Component,
         ElementRef,
         EventEmitter,
         Output,
         QueryList,
         ViewChild,
         ViewChildren                    } from '@angular/core';
import { BehaviorSubject,
         filter,
         take                            } from 'rxjs';
import $                                   from 'jquery';

import { AuthService,
         EnvironmentService,
         LoggerService,
         PushNotificationService,
         SourceService                   } from 'app/core';
import { inOutAnimation                  } from 'app/shared/animations';
import { ExcelTemplateService } from '@app/shared/services/excel-template/excel-template.service';
import { FileService, ParsedFile } from '@app/shared/services/file/file.service';


type Platform = {
  name:    string;
  logo:    string;
  hidden?: boolean;
}

@Component({
    selector: 'app-select-file',
    templateUrl: './select-file.component.html',
    styleUrls: ['./select-file.component.scss'],
    providers: [ExcelTemplateService],
    animations: [inOutAnimation],
    standalone: false
})
export class SelectFileComponent implements AfterViewInit {
  @Output() onSelect = new EventEmitter<ParsedFile>();
  public readonly selected = new BehaviorSubject<ParsedFile | null>(null);

  protected loading: boolean = false;

  protected fileName: string;

  private readonly imgsToBeLoaded = new BehaviorSubject<number>(0);
  protected imagesLoaded = false;

  protected readonly platforms: Platform[] = [];

  @ViewChild('platformsRef') protected platformsRef?: ElementRef;
  @ViewChildren('image')     protected images?: QueryList<ElementRef<HTMLImageElement>>;

  constructor (
    protected auth:          AuthService,
    protected excel:         ExcelTemplateService,
    private   _file:         FileService,
    private   _logger:       LoggerService,
    private   _notification: PushNotificationService,
    private   _environment:  EnvironmentService,
  ) {
    this.platforms = [
      {
        name: "Royal Schedule",
        logo: '/assets/rs-logo-color.svg'
      },
      {
        name: "Excel",
        logo: '/assets/images/svg/excel-logo-with-text.svg'
      },
      {
        name: "Plan Digital",
        logo: '/assets/integrations/schools/logos/plan-digital.svg'
      },
      {
        name: "Stratsys",
        logo: '/assets/images/svg/stratsys-logo-deep-blue.svg'
      },
      {
        name: "InfoMentor",
        logo: '/assets/integrations/schools/logos/infomentor.png',
        hidden: this._environment.whiteLabel == 'schoolsoft'
      },
      {
        name: "Skola24",
        logo: '/assets/integrations/schools/logos/skola24.svg'
      },
      {
        name: "IdunSoft",
        logo: '/assets/integrations/schools/logos/idunsoft.svg',
        hidden: this._environment.whiteLabel == 'schoolsoft'
      }
    ];
  }


  ngAfterViewInit () {
    // wait for all images to be loaded
    this.images?.toArray().forEach(img => {
      this.imgsToBeLoaded.next(this.imgsToBeLoaded.value + 1);
      img.nativeElement.onload = () => this.imgsToBeLoaded.next(this.imgsToBeLoaded.value - 1);
    });

    // must occur after the counter has first been incremented
    this.imgsToBeLoaded
    .pipe(
      filter(x => x == 0),
      take(1)
    )
    .subscribe(() => {
      // make visible to avoid glitchy look
      this.imagesLoaded = true;

      // for all images to have same height, set their aspect ration as the flex grow value
      const ratios: number[] = [];
      this.images?.toArray().forEach(img => {
        const aspect = img.nativeElement.width / img.nativeElement.height;
        if (isNaN(aspect) || ! aspect || ! img.nativeElement.parentElement) return;
        img.nativeElement.parentElement.style.flex = aspect.toString();
        ratios.push(aspect);
      });

      // next we ensure that the images will not overflow the container vertically
      // by increasing the gap between the if necessary
      const $container = $(this.platformsRef?.nativeElement);
      if ( ! $container.length) return

      const W = $container.width()!;
      const H = $container.height()!;
      const minGap = 16;

      // at full height, what is the total width of all images and gaps
      const totImgWidth = ratios.reduce((acc, curr) => acc + curr*H, 0);
      const totMinGap = (ratios.length - 1) * minGap;
      if (totImgWidth + totMinGap < W) {
        // need to increase the gap
        const newGap = (W - totImgWidth) / (ratios.length - 1);
        $container.css('gap', newGap + 'px');
      } else {
        // may use the default gap but the images will not fill the container vertically
        $container.css('gap', minGap + 'px');
      }
    });
  }

  protected fileChanged (event: Event) {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      this.fileName = file.name

      this.loading = true;
      this._file
      .parse(file)
      .then(res => {
        this.loading = false;
        this.onSelect.next(res);
        this.selected.next(res);
      }).catch(err => {
        this.loading = false;
        this._logger.error(new Error(`Could not parse the file ${ this.fileName }`));
        this._notification.pushError();
      });
    }
  }

}
