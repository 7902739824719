import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';

import { TranslationModule                       } from 'app/core/translate/translate.module';
import { AppCommonModule                         } from 'app/common/common.module';
import { SharedFormsModule as MyFormsModule            } from 'app/shared/forms/forms.module';
import { SharedPipesModule                             } from 'app/shared/pipes/pipes.module';
import { ComponentsModule                        } from './components/components.module';
import { SharedComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';
import { UploadFileComponent                     } from './upload-file.component';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';

@NgModule({
  declarations: [
    UploadFileComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    MyFormsModule,
    ComponentsModule,
    SharedPipesModule,
    AppComponentsModule,
    LoadingComponent
  ]
})
export class UploadFileModule { }
