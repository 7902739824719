import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';
import { DragDropModule                  } from '@angular/cdk/drag-drop';

import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';

import { SharedComponentsModule as AppShared   } from 'app/shared/components/components.module';
import { SharedPipesModule                     } from 'app/shared/pipes/pipes.module';
import { FilterComponent                 } from './filter.component';
import { CollectionIconPipe,
         FilterActivePipe                } from './filter.pipe';



@NgModule({
  declarations: [
    FilterComponent,
    CollectionIconPipe,
    FilterActivePipe
  ],
  exports: [
    FilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppShared,
    SharedPipesModule,
    DragDropModule,
    AppCommonModule,
    TranslationModule
  ]
})
export class FilterModule { }
