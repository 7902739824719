import { Component,
         EventEmitter,
         ViewChild,
         Input,
         Output,
         AfterViewInit,
         DestroyRef,
         inject                                } from '@angular/core';
import { FormControl,
         FormGroup,
         Validators,                           } from '@angular/forms';
import { takeUntilDestroyed                    } from '@angular/core/rxjs-interop';
import { debounceTime,
         BehaviorSubject,
         combineLatest,
         startWith,
         filter,
         switchMap,
         merge,
         map                                   } from 'rxjs';
import { MapName,
         PlanDigitalTypes                      } from '@royalschedule/maps';
import moment                                    from 'moment';

import { TranslateService                      } from 'app/core';
import { DivisionComponent as DivisionForm     } from 'app/shared/forms/division/division.component';
import { PartialDivision                       } from '../../upload-file.component';


type PlanDigitalNameKeys = keyof Pick<PlanDigitalTypes.course, 'Kursnamn' | 'Gruppnamn'>;

type SecondaryFormControls_PlanDigital = {
  selectedPeriods: FormControl<string[] | null>;
  displayName:     FormControl<PlanDigitalNameKeys>;
};

type SecondaryFormControls_Skola24 = {
  week:                       FormControl<number  | null>;
  ignoreEmptyCourses:         FormControl<boolean>;
  deduceGroupsFromCourseName: FormControl<boolean>;
  removeUnreferencedTeachers: FormControl<boolean>;
  removeUnreferencedGroups:   FormControl<boolean>;
};

type SecondaryFormControls_SchoolSoft = {
  interval: FormControl<{ start: number, end: number } | null>;
};

type SecondaryForms = {
  'Plan Digital': FormGroup<SecondaryFormControls_PlanDigital>;
  'Skola24':      FormGroup<SecondaryFormControls_Skola24>;
  'SchoolSoft':   FormGroup<SecondaryFormControls_SchoolSoft>;
};

export type FormData = {
  division: PartialDivision;
  options?: SecondaryForms[keyof SecondaryForms]['value'];
};

@Component({
    selector: 'app-configure',
    templateUrl: './configure.component.html',
    styleUrls: ['./configure.component.scss'],
    standalone: false
})
export class ConfigureComponent implements AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);

  @Output()
  readonly validChange= new EventEmitter<boolean>();

  @ViewChild(DivisionForm)
  private divisionForm?: DivisionForm;

  protected readonly planDigitalNameKeys: PlanDigitalNameKeys[] = ['Kursnamn', 'Gruppnamn'];

  protected readonly secondaryForms: SecondaryForms = {
    'Plan Digital': new FormGroup<SecondaryFormControls_PlanDigital>({
      selectedPeriods: new FormControl(null, { validators: Validators.required }),
      displayName:     new FormControl(this.planDigitalNameKeys[0], { nonNullable: true })
    }),
    'Skola24': new FormGroup<SecondaryFormControls_Skola24>({
      week:                       new FormControl(null),
      ignoreEmptyCourses:         new FormControl(true, { nonNullable: true }),
      deduceGroupsFromCourseName: new FormControl(true, { nonNullable: true }),
      removeUnreferencedTeachers: new FormControl(true, { nonNullable: true }),
      removeUnreferencedGroups:   new FormControl(true, { nonNullable: true })
    }),
    'SchoolSoft': new FormGroup<SecondaryFormControls_SchoolSoft>({
      interval: new FormControl(null)
    })
  };

  protected readonly weekRange = new BehaviorSubject<{ start: string, end: string }>({ start: '?', end: '?' });

  protected readonly periods = new BehaviorSubject<{ Namn: string }[] | null>(null);

  // week 1 to 53
  protected readonly weeks: number[] = Array.from({ length: 52 }, (_, i) => i + 1);


  constructor (
    private _translate: TranslateService
  ) { }

  ngAfterViewInit () {
    if ( ! this.divisionForm) return;

    // analyze input
    combineLatest({
      mapName:  this._mapName.pipe(filter(Boolean)),
      input:    this._input  .pipe(filter(Boolean))
    })
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(({ mapName, input }) => {

      if (mapName === 'Royal Schedule') {
        // if the map is the Royal Schedule one, attempt to parse the data and extract the division
        // must have a division entry of object type
        if (                        input          && typeof input          == 'object'
          && 'division' in input && input.division && typeof input.division == 'object'
        ) {
          const d = input.division;
          const division = {
            ...'displayName' in d && typeof d.displayName == 'string' ? { displayName: d.displayName } : { },
            ...'start'       in d && typeof d.start       == 'string' ? { start:       d.start       } : { },
            ...'end'         in d && typeof d.end         == 'string' ? { end:         d.end         } : { },
          };
          this.divisionForm?.form.controls.displayName.setValue(division.displayName);
          this.divisionForm?.form.controls.start      .setValue(division.start);
          this.divisionForm?.form.controls.end        .setValue(division.end);
        }
      }
      else if (/Royal Schedule Algorithm v\d+/.test(mapName)){
        // if the map is the Royal Schedule one, attempt to parse the data and extract the division
        // must have a meta.division entry of object type
        if (                             input               && typeof input               == 'object'
          && 'meta'     in input      && input.meta          && typeof input.meta          == 'object'
          && 'division' in input.meta && input.meta.division && typeof input.meta.division == 'object'
        ) {
          const d = input.meta.division;
          const division = {
            ...'displayName' in d && typeof d.displayName == 'string' ? { displayName: d.displayName } : { },
            ...'start'       in d && typeof d.start       == 'string' ? { start:       d.start       } : { },
            ...'end'         in d && typeof d.end         == 'string' ? { end:         d.end         } : { },
          };
          this.divisionForm?.form.controls.displayName.setValue(division.displayName);
          this.divisionForm?.form.controls.start      .setValue(division.start);
          this.divisionForm?.form.controls.end        .setValue(division.end);
        }
      }
      else if (mapName == 'Plan Digital') {
        // fetch periods
        if (input && typeof input == 'object' && 'Perioder' in input && Array.isArray(input.Perioder)) {
          this.periods.next(input.Perioder);
        }
      }
    });

    // update secondary form based on division form and map name
    combineLatest({
      mapName:  this._mapName                      .pipe(filter(Boolean)),
      division: this.divisionForm.form.valueChanges.pipe(startWith(this.divisionForm?.form.value))
    })
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(0)
    )
    .subscribe(({ division, mapName }) => {

      if (mapName == 'Skola24') {
        // update initial week value based on division start but only if untouched
        if (this.secondaryForms['Skola24'].controls.week.pristine)
          this.secondaryForms['Skola24'].controls.week.setValue(moment.utc(division.start).isoWeek());
      }

      else if (mapName == 'SchoolSoft') {
        const range = this.getWeekRange();

        // update range but only if truthy
        if (this.secondaryForms['SchoolSoft'].controls.interval.value)
          this.secondaryForms['SchoolSoft'].controls.interval.setValue(range);

        // update week range
        this.weekRange.next({
          start: this._translate.instant('common.week_short') + ' ' + (isNaN(range.start) ? '?' : range.start),
          end:   this._translate.instant('common.week_short') + ' ' + (isNaN(range.end)   ? '?' : range.end)
        });
      }
    });

    // emit valid state changes
    merge(
      this.divisionForm.form.valueChanges,
      this._mapName.pipe(
        filter(Boolean),
        filter(x => x in this.secondaryForms),
        switchMap(x => this.secondaryForms[x as keyof SecondaryForms].valueChanges)
      )
    )
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      startWith(null),
      map(() => this.valid)
    )
    .subscribe(x => this.validChange.emit(x));

    // mirror division form value changes
    this.divisionForm.form.valueChanges
    .pipe(
      takeUntilDestroyed(this.destroyRef),
      startWith(this.divisionForm.form.value),
      debounceTime(0)   // to prevent ExpressionChangedAfterItHasBeenCheckedError
    )
    .subscribe(this.division);

    // mirror secondary form value changes
    this._mapName.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(Boolean),
      filter(x => x in this.secondaryForms),
      switchMap(x => this.secondaryForms[x as keyof SecondaryForms].valueChanges)
    )
    .subscribe(this.options);
  }

  protected getWeekRange () {
    const { start, end } = this.divisionForm?.form.value ?? { };
    return { start: moment.utc(start).isoWeek(), end: moment.utc(end).isoWeek() };
  }

  @Input()
  get mapName ():     MapName | null  { return this._mapName.value; }
  set mapName (value: MapName | undefined) { this._mapName.next(value ?? null); }
  private _mapName = new BehaviorSubject<MapName | null>(null);

  @Input()
  set input (value: unknown) { this._input.next(value); }
  private _input = new BehaviorSubject<unknown>(null);

  get valid (): boolean {
    const secondaryForm = this.mapName && this.mapName in this.secondaryForms
      ? this.secondaryForms[this.mapName as keyof SecondaryForms]
      : null;

    return (!! this.divisionForm?.form.valid) && (secondaryForm ? secondaryForm.valid : true);
  }

  public division = new BehaviorSubject<PartialDivision | null>(null);
  public options  = new BehaviorSubject<SecondaryForms[keyof SecondaryForms]['value'] | null>(null);

}
