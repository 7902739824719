import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';

import { AppCommonModule                 } from 'app/common/common.module';

import { TranslationModule               } from 'app/core/translate/translate.module';

import { SharedPipesModule                     } from 'app/shared/pipes/pipes.module';

import { CoursesModule                   } from './courses/courses.module';
import { EventsModule                    } from './events/events.module';

@NgModule({
  declarations: [
  ],
  exports: [
    CoursesModule,
    EventsModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    SharedPipesModule
  ]
})
export class ComponentsModule { }
